import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { endpoints } from '../../../utils/URL';
import { addToast } from '../../../utils/toastNotifications';
import { isFormValidated } from '../../../utils/formUtils';
import ButtonLoader from '../../common/ButtonLoader';
// import './styles.css';
import { formatInputDate } from '../../../utils/dateTimeUtils';
import { validFileType } from '../../../utils/fileHelper';
import { IHmoReportDTO, defaultReport } from '../../../interface/MiscInterface';
import { initialFulfilmentPartnersData } from '../../../interface/FulfilmentPartnersInterface';
import { HmoFileReport, HmoFileReportMap } from '../../../utils/mappings';

const HmoReportForm = () => {
  const [isLoading, setLoading] = useState(false);
  const [defaultReportData, setInitialExportData] = useState<IHmoReportDTO>(defaultReport);
  const [fileUrl, setFileUrl] = useState('');
  const [progressState, setProgressState] = useState('progress-bar');
  const [tableData, setTableData] = useState([initialFulfilmentPartnersData]);
  const [selectedFileType, setSelectedFileType] = useState(HmoFileReport[0].value);

  const handleSelectChange = (event: any) => {
    setSelectedFileType(event.target.value);
    let fileUpload: any = document.getElementById('hmo-file');
    let progressBar = document.getElementById('progress-bar') as HTMLElement;

    fileUpload.value = '';
    progressBar.style.width = `0%`;
  };

  const clearForm = () => {
    setLoading(false);
    setProgressState('');
    setFileUrl('');
    setInitialExportData(defaultReportData);
    const form = document.getElementById!('report-form') as HTMLFormElement;
    form.reset();
    let progressBar = document.getElementById('progress-bar') as HTMLElement;
    progressBar.style.width = `0%`;
  };

  const uploadFile = async (event: any) => {
    let fileUpload: any = document.getElementById('hmo-file');

    let isFormValid = document.getElementById('hmo-file');
    if (!isFormValid) {
      addToast('Select a file');
      return;
    }

    let uploadedFiles = fileUpload.files;

    if (uploadedFiles.length === 0) {
      addToast('No files selected. Please select a csv file', 'warning');
      return;
    }

    if (selectedFileType === HmoFileReportMap.ratingFileUrl && !validFileType(uploadedFiles[0])) {
      addToast('Wrong file. Upload text or csv file', 'warning');
      return;
    }

    // if (
    //   selectedFileType === HmoFileReportMap.graphImageFileUrl &&
    //   !validImageFileType(uploadedFiles[0])
    // ) {
    //   addToast('Wrong file. Upload an image file', 'warning');
    //   return;
    // }

    if (uploadedFiles[0].size > 2097152) {
      //if exceeding 2mb
      addToast('Exceed file limit. Max limit is 2MB', 'warning');
      return;
    }

    event.preventDefault();

    const formData = new FormData();
    formData.append('formFile', uploadedFiles[0]);

    let url = `${endpoints.Jobs.backgroundQueue}/files`;
    let progressBar = document.getElementById('progress-bar') as HTMLElement;
    progressBar.style.width = `0%`;

    var config = {
      onUploadProgress: function (progressEvent: any) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        progressBar.style.width = `${[percentCompleted]}%`;
      },
    };

    try {
      const response = await axios.post(url, formData, config);
      setProgressState('progress-bar bg-success');
      setFileUrl(response.data);

      setInitialExportData({
        ...defaultReportData,
        [selectedFileType]: response.data,
      });

      addToast('File uploaded successfully', 'success');
      //addT
    } catch (error: any) {
      if (error.response && error.response.status === 422) {
        addToast('Please check the file type', 'error');
      } else {
        addToast('An Error occured. Check your network', 'error');
      }
      setProgressState('progress-bar bg-danger');
      progressBar.style.width = `0%`;
      setProgressState('progress-bar');
    } finally {
    }
  };

  const submit = async (e: React.FormEvent) => {
    if (
      !isFormValidated('report-form') ||
      defaultReportData.graphImageFileUrl === '' ||
      defaultReportData.ratingFileUrl === ''
    ) {
      addToast('Please fill all fields and upload a the required files', 'warning');
      return;
    }
    e.preventDefault();

    const payload = defaultReportData;

    //payload.fileUrl = fileUrl;

    let url = `${endpoints.Jobs.backgroundQueue}/hmoreport`;

    try {
      setLoading(true);
      const response = await axios.post(url, payload);
      if (response.status === 200) {
        addToast('Job queued successfull', 'success');
      }
      clearForm();
    } catch (error: any) {
      if (error.response) {
        addToast('There was an error sending your request', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (input: string) => (event: any) => {
    let value = event.target.value;

    setInitialExportData({
      ...defaultReportData,
      [input]: value,
    });

    if (input === 'startDate') {
      let startDate = new Date(event.target.value);
      let endDate = new Date(startDate.setMonth(startDate.getMonth() + 1));
      setInitialExportData({
        ...defaultReportData,
        startDate: value,
        endDate: endDate.toISOString().split('T')[0],
      });
    }
  };

  useEffect(() => {
    const getFulfilmentPartners = async () => {
      let url = endpoints.FulfilmentPartners.mainUrl;

      await axios
        .get(url)
        .then((response) => {
          setTableData(response.data);
        })
        .catch((error) => {
          addToast('An error occured while getting data', 'error');
        });
    };

    getFulfilmentPartners();
  }, []);

  return (
    <Fragment>
      <div className='row justify-content-center '>
        <div className='card col-sm-10 pt-5 pb-5'>
          <h3 style={{ textAlign: 'center' }}>Schedule HMO Report</h3>
          <br />
          <form className='row justify-content-center mt-4' id='report-form'>
            <fieldset id='form-fields' disabled={isLoading ? true : false} className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='email' className='form-label'>
                  Select file type to upload
                </label>
                <select
                  className='custom-select'
                  value={selectedFileType}
                  onChange={handleSelectChange}
                >
                  {HmoFileReport.map((file) => (
                    <option value={file.value}>{file.text}</option>
                  ))}
                </select>
              </div>

              <div className='form-group'>
                <label htmlFor='email' className='form-label'>
                  File URL
                </label>
                <input
                  id='hmo-file'
                  className='form-control'
                  type='file'
                  accept={
                    selectedFileType === HmoFileReportMap.graphImageFileUrl
                      ? '.csv, .txt' //'.svg, .png, .jpeg, .jpg'
                      : '.csv, .txt'
                  }
                  onChange={uploadFile}
                />
                <div className={'progress'} style={{ height: '3px', marginTop: '10px' }}>
                  <div
                    id='progress-bar'
                    className={progressState}
                    role='progressbar'
                    style={{ width: '0%' }}
                    aria-valuenow={0}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
                <div className='mt-3'>
                  {defaultReportData.graphImageFileUrl !== '' ? (
                    <div title={defaultReportData.graphImageFileUrl}>1. HMO TAT Data </div>
                  ) : (
                    ''
                  )}
                  {defaultReportData.ratingFileUrl !== '' ? (
                    <div className='mt-2' title={defaultReportData.ratingFileUrl}>
                      2. Satisfaction Rating File
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <br />

              <div className='form-group'>
                <label htmlFor='email' className='form-label'>
                  Select Partner Name
                </label>
                <select
                  value={defaultReportData.partnerCode}
                  onChange={handleChange('partnerCode')}
                  className='custom-select'
                  required
                >
                  {tableData.map((partner) => (
                    <option value={partner.partnerCode}>{partner.partnerName}</option>
                  ))}
                  {/* <option value='tht'>Total Health Trust</option>
                  <option value='alleanza'>Alleanza</option> */}
                </select>
              </div>
              <br />
              <div className='form-group'>
                <label className='form-label'>Amount Saved</label>
                <input
                  className='form-control '
                  type='number'
                  min={0}
                  value={defaultReportData.amountSaved}
                  onChange={handleChange('amountSaved')}
                />
              </div>
              <div className='form-group'>
                <label className='form-label'>Satisfaction Score</label>
                <input
                  className='form-control '
                  type='number'
                  min={0}
                  max={5}
                  step={0.01}
                  value={defaultReportData.satisfactionScore}
                  onChange={handleChange('satisfactionScore')}
                />
              </div>
              <div className='form-group'>
                <label htmlFor='endDate' className='form-label'>
                  Report Start Date
                </label>
                <input
                  value={formatInputDate(defaultReportData.startDate)}
                  className='form-control '
                  type='date'
                  required
                  onChange={handleChange('startDate')}
                />
              </div>
              <div className='form-group'>
                <label htmlFor='endDate' className='form-label'>
                  Report End Date
                </label>
                <input
                  value={formatInputDate(defaultReportData.endDate)}
                  className='form-control '
                  type='date'
                  onChange={handleChange('endDate')}
                />
                <small>
                  Note, report is calculated e.g between 1st and 30th inclusive, so use 1st of the
                  following month e.g 1st Jan to 1st Feb
                </small>
              </div>
              <div className='form-group'>
                <label htmlFor='endDate' className='form-label'>
                  Queue Date
                </label>
                <input
                  value={formatInputDate(defaultReportData.queueDate)}
                  className='form-control '
                  type='date'
                  onChange={handleChange('queueDate')}
                />
              </div>
              <div className='form-group'>
                <label htmlFor='endDate' className='form-label'>
                  Additional Recepients
                </label>
                <input
                  value={defaultReportData.additionalRecipients}
                  className='form-control '
                  type='text'
                  onChange={handleChange('additionalRecipients')}
                  placeholder='Enter email addresses separated by comma'
                />
              </div>
              <br />
              <button
                className='btn btn-block btn-primary'
                disabled={fileUrl === ''}
                onClick={submit}
              >
                Queue
                {isLoading ? <ButtonLoader /> : ''}
              </button>
            </fieldset>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default HmoReportForm;
