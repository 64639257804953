import React, { useState, useEffect, useCallback, useContext } from 'react';
import { endpoints } from '../../../utils/URL';
import axios from 'axios';
import { IWalletAccountDTO, IWalletTransactionData } from '../../../interface/WalletsInterface';
import { addToast } from '../../../utils/toastNotifications';
import { formatDateTime } from '../../../utils/dateTimeUtils';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../context/AppContext';
import { IAppState, IRealTimeUpdateProp } from '../../../interface/AppInterface';
import PaginationBar from '../../common/Pagination';
import { defaultPageInfo } from '../../../interface/CommonInterface';
import EditTransactionRequestsModal from './EditTransactionRequestsModal';

const WalletTransactionsTable = () => {
  const { accountId } = useParams() as any;
  const { appState }: { appState: IAppState } = useContext(AppContext);

  const [modalData, setModalData] = useState<IWalletTransactionData | undefined>();
  const [tableData, setTableData] = useState<IWalletTransactionData[]>([]);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  // const [setAddModalOpen] = useState(false);
  const [transactionTotal, setTransactionTotal] = useState({
    totalDebits: 0,
    totalCredits: 0,
  });

  const [selectedWallet, setSelectedWallet] = useState<IWalletAccountDTO | undefined>();

  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const [showPagination, setShowPagination] = useState(false);

  // const openTransactionModal = () => {
  //   setAddModalOpen(true);
  // };

  // const closeTransactionModal = () => {
  //   setAddModalOpen(false);
  //   getAccountDetails();
  // };

  // const openEditTransactionModal = (transactionData: IWalletTransactionData) => {
  //   setModalData(transactionData);
  //   setEditModalOpen(true);
  // };

  const getTransactions = useCallback(
    async (pageIndex = 1) => {
      //get accountId from url, if no accountId, load all debit requests

      let url = `${endpoints.WalletTransactions.mainUrl}?pageIndex=${pageIndex}&pageSize=200`;

      if (typeof accountId !== 'undefined' && accountId !== '') {
        url = `${endpoints.WalletTransactions.mainUrl}/account/${accountId}?pageIndex=${pageIndex}&pageSize=200`;
      }

      try {
        const response = await axios.get(url);

        const transactionData = response.data.data as IWalletTransactionData[];

        setTableData(transactionData); //response.data.data);

        //sum credits and debits
        //const transactionData = response.data.data as IWalletTransactionData[];
        const totalCredits = transactionData
          .filter((x) => x.transactionType === 'Credit')
          .reduce((a, b) => a + b.amount, 0);

        const totalDebits = transactionData
          .filter((x) => x.transactionType === 'Debit')
          .reduce((a, b) => a + b.amount, 0);

        setTransactionTotal({
          totalCredits: parseFloat(totalCredits.toFixed(2)),
          totalDebits: parseFloat(totalDebits.toFixed(2)),
        });

        //set pagination info
        const responsePageInfo = {
          pageIndex: response.data.pageIndex,
          pageSize: response.data.pageSize,
          pageCount: response.data.pageCount,
        };
        //set page info
        setPageInfo(responsePageInfo);
        setShowPagination(true);
      } catch (error: any) {
        addToast('There was error getting debit requests', 'error');
      }
    },
    [accountId]
  );

  const getAccountDetails = useCallback(async () => {
    //get accountId from url, if no accountId, load all debit requests

    if (typeof accountId === 'undefined' || accountId === '') {
      return;
    }

    let url = `${endpoints.WalletAccounts.mainUrl}/${accountId}`;

    try {
      const response = await axios.get(url);
      const accountData = response.data as IWalletAccountDTO;
      setSelectedWallet(accountData);
    } catch (error: any) {
      addToast('There was error getting debit requests', 'error');
    }
  }, [accountId]);

  const handleChange = (input: string) => (e: any) => {
    setModalData({
      ...modalData!,
      [input]: e.target.value,
    });
  };

  const getUpdatedTransactions = async (transactionId: string) => {
    //get the fulfilment using requestId
    //filter current data where requestId == updatedFulfilment request Id
    //if found, get index of found object from current data
    //update the current data by replacing the old data with new data
    let url = `${endpoints.WalletTransactions.mainUrl}/${transactionId}`;

    try {
      const response = await axios.get(url);
      const updatedData = response.data;

      const oldData = tableData.filter((x) => x.transactionId === transactionId)[0];
      const parsedOldData = oldData || null;
      const filteredData: any = [...tableData];

      if (parsedOldData !== null) {
        filteredData[filteredData.indexOf(parsedOldData)] = updatedData;
      } else {
        filteredData.unshift(updatedData);
      }
      setTableData(tableData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTransactions();
  }, [getTransactions]);

  useEffect(() => {
    getAccountDetails();
  }, [getAccountDetails]);

  useEffect(() => {
    //use the transaction id to update
    const realTimeData = appState.walletTransactions as IRealTimeUpdateProp;

    //all efforts to move this out of the useEffect was not possible without
    //resorting to suppressing es-lint warnings

    if (realTimeData != null) {
      getUpdatedTransactions(realTimeData.dataId);
    }
  }, [appState.walletTransactions]); // eslint-disable-line react-hooks/exhaustive-deps

  let updateTransaction = (
    <EditTransactionRequestsModal
      open={isEditModalOpen}
      close={() => setEditModalOpen(false)}
      modalData={modalData!}
      getTransactions={getTransactions}
      handleChange={handleChange}
    />
  );

  // let addTansactionModal = (
  //   <AddTransactionModal
  //     open={isAddModalOpen}
  //     close={closeTransactionModal}
  //     selectedWallet={selectedWallet!}
  //   />
  // );

  let tableHead: JSX.Element = (
    <thead>
      <tr>
        <th scope='col'>Actions</th>
        <th scope='col'>Transaction Id</th>
        <th scope='col'>Transaction Date</th>
        <th scope='col'>Pharmacy Code</th>
        <th scope='col'>Pharmacy Name</th>
        <th scope='col'>Debit Type</th>
        <th scope='col'>Amount (₦)</th>
        <th scope='col'>Balance (₦)</th>
        <th scope='col'>Transaction Status</th>
        <th scope='col'>Created By</th>
        <th scope='col'>Approved By</th>
        <th scope='col'>Processed Date</th>
        <th scope='col'>Notes</th>
      </tr>
    </thead>
  );

  let tableBody: JSX.Element = (
    <tbody>
      {tableData.map((transaction: IWalletTransactionData) => {
        return (
          <tr
            key={transaction.transactionId}
            className={transaction.transactionType === 'Credit' ? 'text-success' : 'text-danger'}
          >
            <td>
              <i
                style={{ cursor: 'pointer' }}
                // onClick={() => openEditTransactionModal(transaction)}
                className='bi bi-pencil-square export-icon'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                title='Edit'
              ></i>
            </td>
            <td>{transaction.transactionId}</td>
            <td>{formatDateTime(transaction.transactionDate)}</td>
            <td>{transaction.providerCode}</td>
            <td>{transaction.providerName}</td>
            <td>{transaction.descriptionType}</td>
            <td>{transaction.amount.toLocaleString()}</td>
            <td>{transaction.balance.toLocaleString()}</td>
            <td>{transaction.transactionStatus}</td>
            <td>{transaction.createdBy}</td>
            <td>{transaction.approvedBy}</td>
            <td>{formatDateTime(transaction.processedDate)}</td>
            <td>{transaction.notes}</td>
          </tr>
        );
      })}
    </tbody>
  );

  return (
    <div>
      {updateTransaction}
      <div className='d-flex'>
        <h3 className=' mr-auto'>Transaction Requests</h3>
        {typeof accountId !== 'undefined' && accountId !== '' ? (
          <button
            // onClick={() => openTransactionModal()}
            style={{ float: 'right' }}
            className='btn btn-primary'
          >
            Add Transaction
          </button>
        ) : (
          <div></div>
        )}
      </div>
      <br />
      <div>
        Wallet Balance: &nbsp;
        <span className='text-info'>₦{selectedWallet?.walletBalance.toLocaleString()}</span>, Total
        Credit: &nbsp;
        <span className='text-success'>₦{transactionTotal.totalCredits.toLocaleString()}</span>,
        Total Debits: &nbsp;
        <span className='text-danger'>₦{transactionTotal.totalDebits.toLocaleString()}</span>
      </div>

      <div className='table-responsive'>
        <table className='table table-bordered table-hover'>
          {tableHead}
          {tableBody}
        </table>
      </div>
      <br />
      {showPagination ? (
        <PaginationBar
          currentPageNumber={pageInfo.pageIndex}
          numberOfPages={pageInfo.pageCount}
          callback={getTransactions}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default WalletTransactionsTable;
