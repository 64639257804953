import { Modal } from 'react-bootstrap';
import { InsuranceClaimTest } from '../../../interface/ClaimsInterface';
import { TESTRESULT } from '../../../utils/mappings';
import tests from '../../../assets/tests.json';

const AddTestModal = ({
  open,
  close,
  data,
  handleChange,
  addToClaim,
  selectChange,
  testList,
  deleteTest,
}: {
  open: boolean;
  close: Function;
  data: InsuranceClaimTest;
  handleChange: Function;
  addToClaim: Function;
  selectChange: Function;
  testList: Array<InsuranceClaimTest>;
  deleteTest: Function;
}) => {
  // const [selectObject, setSelectObject] = useState<any>([]);

  // const loadTests = useCallback(async () => {
  //   try {
  //     const testArray = tests.tests;
  //     const testData = testArray.map((test, index) => {
  //       return {
  //         id: test.id,
  //         value: test.testCost,
  //         label: test.testName,
  //       };
  //     });
  //     setSelectObject(testData);
  //   } catch (error: any) {
  //     addToast('Error getting drugs and cost', 'error');
  //   }
  // }, []);

  // const dropdownChange = (selectedValue: any) => {
  //   selectChange(selectedValue);
  // };

  // useEffect(() => {
  //   if (open) {
  //     loadTests();
  //   }
  // }, [open, loadTests]);

  return (
    <Modal show={open} onHide={() => close()}>
      <Modal.Header closeButton>Add Test to Claim</Modal.Header>
      <Modal.Body>
        <form>
          <div className='form-group'>
            <label className='form-label'>Name</label>
            {/* <Select
              options={selectObject}
              onChange={dropdownChange}
              className='react-select mb-3'
              classNamePrefix='react-select'
            /> */}
            <input
              type='text'
              className='form-control'
              value={data.testName}
              onChange={handleChange('testName')}
              list='testList'
            />
            <datalist id='testList'>
              {tests.tests.map((test) => {
                return <option key={test.id} value={test.testName} />;
              })}
            </datalist>
          </div>
          <div className='form-group'>
            <label className='form-label'>Result</label>
            <select
              required
              onChange={handleChange('testResult')}
              className='form-control'
              value={data.testResult}
            >
              {TESTRESULT.map((option) => (
                <option key={option.key} value={option.value}>
                  {option.text}
                </option>
              ))}
            </select>
          </div>
          <div className='form-group'>
            <label className='form-label'>Cost</label>
            <input
              type='number'
              className='form-control'
              value={data.cost}
              onChange={handleChange('cost')}
            />
          </div>
          <div className='form-group'>
            <label className='form-label'>Notes</label>
            <textarea
              className='form-control'
              value={data.notes}
              onChange={handleChange('notes')}
            ></textarea>
          </div>
          <div className='form-group'>
            <label className='form-label'>Test Done?</label>
            <input
              type='checkbox'
              className='ml-5'
              checked={data.isCarriedOut}
              onChange={handleChange('isCarriedOut')}
            />
          </div>
          <button className='mt-3 btn btn-primary' type='button' onClick={() => addToClaim()}>
            Add to Claim
          </button>
        </form>
        <hr />
        <div className=' mt-2'>
          <h5 className='text-center'>Tests Added</h5>
          <table className='table'>
            <tbody>
              {testList.map((test) => {
                return (
                  <tr key={test.localId}>
                    <td>{test.testName}</td>
                    <td>{test.cost}</td>
                    <td>
                      <i
                        className='bi bi-trash-fill'
                        style={{ color: 'red' }}
                        onClick={() => deleteTest(test.localId)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default AddTestModal;
