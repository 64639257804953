import React, { useState, useEffect, useCallback, useContext } from 'react';
import { endpoints } from '../../../utils/URL';
import axios from 'axios';
import { IWalletPayoutDTO } from '../../../interface/WalletsInterface';
import { addToast } from '../../../utils/toastNotifications';
import { formatDateTime } from '../../../utils/dateTimeUtils';
import { AppContext } from '../../../context/AppContext';
import { IRealTimeUpdateProp } from '../../../interface/AppInterface';
import PaginationBar from '../../common/Pagination';
import { defaultPageInfo } from '../../../interface/CommonInterface';
import { AllPayoutStatus, AllPayoutStatusMap, PayoutStatus } from '../../../utils/mappings';
import { useParams } from 'react-router-dom';
import WalletPayoutNote from './WalletPayoutNote';

const WalletPayoutsTable = () => {
  const { accountId } = useParams() as any;
  const { appState } = useContext(AppContext);

  const [tableData, setTableData] = useState<IWalletPayoutDTO[]>([]);
  const [isModalOpen, setOpenModal] = useState(false);
  // const [selected, setSelected] = useState<PayoutUpdateDTO>({
  //   payoutStatus: 'Cancelled',
  //   payoutId: '',
  //   notes: '',
  // });

  const [btnLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const [showPagination, setShowPagination] = useState(false);
  const [statusFilter, setStatusFilter] = useState(AllPayoutStatusMap[1].value);

  const getPayoutRequests = useCallback(
    async (pageIndex: number, filter?: string) => {
      //get accountId from url, if no accountId, load all debit requests
      let url = `${endpoints.WalletPayouts.mainUrl}?pageIndex=${pageIndex}&pageSize=200`;

      if (typeof accountId !== 'undefined' && accountId !== '') {
        url = `${endpoints.WalletPayouts.mainUrl}/${accountId}?pageIndex=${pageIndex}&pageSize=200`;
      }

      if (filter !== null && typeof filter !== 'undefined' && filter !== '') {
        url += `&payoutStatus=${filter}`;
      }

      try {
        const response = await axios.get(url);

        const transactionData = response.data.data as IWalletPayoutDTO[];

        setTableData(transactionData); //response.data.data);

        //set pagination info
        const responsePageInfo = {
          pageIndex: response.data.pageIndex,
          pageSize: response.data.pageSize,
          pageCount: response.data.pageCount,
        };
        //set page info
        setPageInfo(responsePageInfo);
        setShowPagination(true);
      } catch (error: any) {
        addToast('There was error getting payout requests', 'error');
      }
    },
    [accountId]
  );

  // const handleClick = (payoutStatus: string, payoutId: string) => async (event: any) => {
  //   setSelected((f) => {
  //     return { ...f, payoutId: payoutId, payoutStatus: payoutStatus, notes: '' };
  //   });

  //   if (payoutStatus === PayoutStatus.Cancelled) {
  //     //show modal
  //     setOpenModal(true);
  //     return;
  //   }

  //   await updatePayoutStatus(payoutId, payoutStatus);
  // };

  // const updatePayoutStatus = async (payoutId: string, payoutStatus: string, notes?: string) => {
  //   //prompt user?
  //   if (payoutId === '' || payoutStatus === '') {
  //     addToast('Please select a payout', 'warning');
  //     return;
  //   }

  //   //if account number is incorrect, alert
  //   const transaction = tableData.filter((x) => x.payoutId === payoutId)[0];

  //   if (transaction.bankAccountNumber === '0000000000' && payoutStatus === PayoutStatus.Approved) {
  //     addToast('Account number for the account is invalid. Please update and try again', 'error');
  //     return;
  //   }

  //   const url = endpoints.WalletPayouts.mainUrl;

  //   setBtnLoading(true);
  //   let payload = {
  //     payoutId: payoutId,
  //     payoutStatus: payoutStatus,
  //     pharmacyCode: transaction.providerCode,
  //     notes: notes,
  //   };
  //   try {
  //     await axios.put(url, payload);
  //     addToast(`Transaction ${payoutStatus}`, 'success');
  //     getPayoutRequests(pageInfo.pageIndex, statusFilter);
  //   } catch (error: any) {
  //     console.error(error);
  //   } finally {
  //     setBtnLoading(false);
  //     //setSelected({ payoutId: '', payoutStatus: '', notes: '' });
  //   }
  // };

  const getUpdatedPayout = async (payoutId: string) => {
    //get the fulfilment using requestId
    //filter current data where requestId == updatedFulfilment request Id
    //if found, get index of found object from current data
    //update the current data by replacing the old data with new data
    let url = `${endpoints.WalletPayouts.mainUrl}/${payoutId}`;

    await axios
      .get(url)
      .then((response) => {
        const updatedData = response.data;

        const oldData = tableData.filter((x) => x.payoutId === payoutId)[0];
        const parsedOldData = oldData || null;
        const filteredData: any = [...tableData];

        if (parsedOldData !== null) {
          filteredData[filteredData.indexOf(parsedOldData)] = updatedData;
        } else {
          filteredData.unshift(updatedData);
        }
        setTableData(tableData);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // const setWalletNotes = async (payoutNote: string) => {
  //   setOpenModal(false);
  //   await updatePayoutStatus(selected.payoutId, selected.payoutStatus, payoutNote);
  // };

  const getStatusColor = (status: string) => {
    switch (status) {
      case AllPayoutStatus.Pending:
        return 'text-primary';
      case AllPayoutStatus.Approved:
        return 'text-info';
      case AllPayoutStatus.Processing:
      case AllPayoutStatus.Retrying:
        return 'text-secondary';
      case AllPayoutStatus.Successful:
        return 'text-success';
      case AllPayoutStatus.Cancelled:
        return 'text-danger';
      case AllPayoutStatus.Failed:
        return 'text-warning';
      default:
        return 'text-info';
    }
  };

  const filterPayouts = (event: any) => {
    const filter = event.target.value;
    setStatusFilter(filter);
    getPayoutRequests(1, filter);
  };

  useEffect(() => {
    getPayoutRequests(1, statusFilter);
  }, [getPayoutRequests, statusFilter]);

  useEffect(() => {
    //use the transaction id to update
    const realTimeData = appState.walletTransactions as IRealTimeUpdateProp;

    //all efforts to move this out of the useEffect was not possible without
    //resorting to suppressing es-lint warnings

    if (realTimeData != null) {
      getUpdatedPayout(realTimeData.dataId);
    }
  }, [appState.walletTransactions]); // eslint-disable-line react-hooks/exhaustive-deps

  let tableHead: JSX.Element = (
    <thead>
      <tr>
        <th scope='col'>Actions</th>
        <th scope='col'>Transaction Date</th>
        <th scope='col'>Payout Status</th>
        <th scope='col'>Pharmacy Code</th>
        <th scope='col'>Pharmacy Name</th>
        <th scope='col'>Debit Type</th>
        <th scope='col'>Amount (₦)</th>
        <th scope='col'>Balance (₦)</th>
        <th scope='col'>Bank Name</th>
        <th scope='col'>Bank Account Name</th>
        <th scope='col'>Bank Account Number</th>
        <th scope='col'>Created By</th>
        <th scope='col'>Approved By</th>
        <th scope='col'>Processed Date</th>
        <th scope='col'>Notes</th>
      </tr>
    </thead>
  );

  let tableBody: JSX.Element = (
    <tbody>
      {tableData.map((payout: IWalletPayoutDTO) => {
        return (
          <tr key={payout.payoutId} className={getStatusColor(payout.payoutStatus)}>
            <td>
              <fieldset disabled={btnLoading}>
                {payout.payoutStatus === PayoutStatus.Pending ? (
                  <div>
                    <button
                      className='btn btn-success btn-sm btn-block'
                      // onClick={handleClick(PayoutStatus.Approved, payout.payoutId)}
                    >
                      Approve
                    </button>
                  </div>
                ) : (
                  ''
                )}

                {payout.payoutStatus === PayoutStatus.Pending ||
                payout.payoutStatus === PayoutStatus.Approved ? (
                  <button
                    className='btn btn-danger btn-sm btn-block mt-2'
                    // onClick={handleClick(PayoutStatus.Cancelled, payout.payoutId)}
                  >
                    Cancel
                  </button>
                ) : (
                  ''
                )}
              </fieldset>
            </td>
            <td>{formatDateTime(payout.dateCreated)}</td>
            <td>{payout.payoutStatus}</td>
            <td>{payout.providerCode}</td>
            <td>{payout.providerName}</td>
            <td>{payout.descriptionType}</td>
            <td>{payout.amount.toLocaleString()}</td>
            <td>{payout.balance.toLocaleString()}</td>
            <td>{payout.bankName}</td>
            <td>{payout.bankAccountName}</td>
            <td>{payout.bankAccountNumber}</td>
            <td>{payout.createdBy}</td>
            <td>{payout.approvedBy}</td>
            <td>{formatDateTime(payout.processedDate)}</td>
            <td>{payout.notes}</td>
          </tr>
        );
      })}
    </tbody>
  );

  let notesModal = (
    <WalletPayoutNote
      open={isModalOpen}
      close={() => setOpenModal(!isModalOpen)}
      callback={undefined}
    />
  );

  return (
    <div>
      {notesModal}
      <div className='d-flex'>
        <h3 className=' mr-auto'>Payout Requests</h3>
      </div>
      <br />
      <div className='row'>
        <div className='col-6'>
          <div className='form-inline'>
            <input
              type='search'
              className='form-control'
              placeholder='Search by pharmacy name or pharmacy code'
              //onChange={searchTable}
            />
          </div>
        </div>
        <div className='col-6'>
          <div className='form-inline float-right mr-5'>
            <select className='custom-select' value={statusFilter} onChange={filterPayouts}>
              {AllPayoutStatusMap.map((status) => (
                <option key={status.key} value={status.value}>
                  {status.text}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className='table-responsive mt-4'>
        <table className='table table-bordered table-hover'>
          {tableHead}
          {tableBody}
        </table>
      </div>
      <br />
      {showPagination ? (
        <PaginationBar
          currentPageNumber={pageInfo.pageIndex}
          numberOfPages={pageInfo.pageCount}
          callback={getPayoutRequests}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default WalletPayoutsTable;
