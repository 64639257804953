import axios from 'axios';
import {
  ChangeEvent,
  FormEvent,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { IClaims, initialClaim } from '../../../interface/ClaimsInterface';
import { addToast } from '../../../utils/toastNotifications';
import { endpoints } from '../../../utils/URL';
import PharmacyDetailsModal from './PharmacyDetailsModal';
import SubscriptionDetailsModal from './SubscriptionDetailsModal';
import EditClaimModal from './EditClaimModal';
// import EditDrugsModal from './EditDrugsModal';
import './styles.css';
import { formatDate, validateDateRange } from '../../../utils/dateTimeUtils';
import { useParams } from 'react-router-dom';
import { IRealTimeUpdateProp } from '../../../interface/AppInterface';
import { AppContext } from '../../../context/AppContext';
import { defaultPageInfo } from '../../../interface/CommonInterface';
import PaginationBar from '../../common/Pagination';
import { isFormValidated } from '../../../utils/formUtils';
//import EditPharmacyRatingModal from './EditPharamcyRatingsModal';

const ClaimsTable = () => {
  const { subscriptionCode } = useParams() as any;

  const [data, setData] = useState<IClaims[]>([]);
  const [pharmacyModalOpen, setPharmacyModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [subscriptionDetailsCode, setSubscriptionDetailsCode] = useState('');
  const [subscriptionDetailsModalOpen, setSubscriptionDetailsModalOpen] = useState(false);
  const [pharmacyCode, setPharmacyCode] = useState('');
  const [editModalData, setEditModalData] = useState(initialClaim);
  const { appState } = useContext(AppContext);
  const [filters, setFilters] = useState({
    startDate: '',
    endDate: '',
  });

  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const [showPagination, setShowPagination] = useState(false);

  const loadPharmacyDetails = (pharmacyCode: string) => {
    setPharmacyCode(pharmacyCode);
    setPharmacyModalOpen(true);
  };

  const loadSubscriptionDetails = (subscriptionCode: string) => {
    setSubscriptionDetailsCode(subscriptionCode);
    setSubscriptionDetailsModalOpen(true);
  };

  const handleFilter = (input: string) => (event: ChangeEvent<HTMLInputElement>) => {
    setFilters({
      ...filters,
      [input]: event.target.value,
    });
  };

  // const openDrugModal = (drugs: string) => {
  //   setDrugsModalData(drugs);
  //   setDrugsModalOpen(true);
  // };

  const handleChange = (input: string) => (event: any) => {
    setEditModalData({
      ...editModalData,
      [input]: event.target.value,
    });
  };

  const editClaimsData = (item: IClaims) => {
    setEditModalData(item);
    setEditModalOpen(true);
  };

  const closePharmacyModal = () => {
    setPharmacyModalOpen(false);
  };

  const closeSubscriptionModal = () => {
    setSubscriptionDetailsModalOpen(false);
  };

  // const closeDrugsModal = () => {
  //   setDrugsModalOpen(false);
  // };

  const searchClaims = async (event: FormEvent) => {
    if (!isFormValidated('claim-filter')) {
      return;
    }
    event.preventDefault();

    const result = validateDateRange(filters.startDate, filters.endDate);

    if (!result.isValid) {
      addToast(result.msg, 'error');
      return;
    }

    await loadClaims(1, filters.startDate, filters.endDate);
  };

  const paginationCallback = async (pageIndex: number) => {
    await loadClaims(pageIndex, filters.startDate, filters.endDate);
  };

  const clearFilters = (event: FormEvent) => {
    event.preventDefault();

    setFilters({
      ...filters,
      startDate: '',
      endDate: '',
    });

    loadClaims(1, '', '');
  };

  const loadClaims = useCallback(
    async (pageIndex: number, startDate: string, endDate: string) => {
      try {
        let url = `${endpoints.InsuranceClaims.mainUrl}?pageSize=200&pageIndex=${pageIndex}`;

        if (startDate !== '') {
          url += `&startDate=${startDate}`;
          if (endDate !== '') {
            url += `&endDate=${endDate}`;
          }
        }

        if (typeof subscriptionCode !== 'undefined' && subscriptionCode !== '') {
          url = `${endpoints.InsuranceClaims.mainUrl}/subscriptioncode/${subscriptionCode}`;
        }

        const response = await axios.get(url);

        if (response.data.length === 0) {
          addToast('No claims found for the selected subscription code', 'info');
        }
        setData(response.data.data);
        const responsePageInfo = {
          pageIndex: response.data.pageIndex,
          pageSize: response.data.pageSize,
          pageCount: response.data.pageCount,
        };
        //set page info
        setPageInfo(responsePageInfo);
        setShowPagination(true);
      } catch (error: any) {
        addToast('Error loading claims', 'error');
      }
    },
    [subscriptionCode]
  );

  const getUpdatedClaims = async (claimId: number) => {
    let url = `${endpoints.InsuranceClaims.mainUrl}/${claimId}`;

    await axios
      .get(url)
      .then((response) => {
        const updatedData = response.data;

        const oldData = data.filter((x) => x.insuranceClaimId === claimId)[0];
        const parsedOldData = oldData || null;
        const filteredData: any = [...data];

        if (parsedOldData !== null) {
          filteredData[filteredData.indexOf(parsedOldData)] = updatedData;
        } else {
          filteredData.unshift(updatedData);
        }
        setData(filteredData);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    loadClaims(1, '', '');
  }, [loadClaims]);

  useEffect(() => {
    //use the request id to update
    const realTimeData = appState.insuranceClaims as IRealTimeUpdateProp;

    //all efforts to move this out of the useEffect was not possible without
    //resorting to suppressing es-lint warnings

    if (realTimeData != null) {
      const dataId = parseInt(realTimeData.dataId);
      getUpdatedClaims(dataId);
    }
  }, [appState.insuranceClaims]); // eslint-disable-line react-hooks/exhaustive-deps

  const closeModal = () => {
    setEditModalOpen(false);
  };

  let pharmacyDetails = (
    <PharmacyDetailsModal
      open={pharmacyModalOpen}
      close={closePharmacyModal}
      pharmacyCode={pharmacyCode}
    />
  );

  let subscriptionDetails = (
    <SubscriptionDetailsModal
      open={subscriptionDetailsModalOpen}
      close={closeSubscriptionModal}
      subscriptionCode={subscriptionDetailsCode}
    />
  );

  let editModal = (
    <EditClaimModal
      open={editModalOpen}
      data={editModalData}
      close={closeModal}
      handleChange={handleChange}
      loadClaims={() => paginationCallback(pageInfo.pageIndex)}
    />
  );

  // let editPharmacyRatingModal = (
  //   <EditPharmacyRatingModal
  //     open={editPharmacyRatingModalOpen}
  //     close={closePharmacyRatingModal}
  //     data={editPharmacyRatingModalData}
  //   />
  // );

  return (
    <Fragment>
      {editModal}
      {pharmacyDetails}
      {subscriptionDetails}
      {/* {editPharmacyRatingModal} */}
      <h3 className='mr-auto'>Claims</h3>
      <div className='col-sm-8 mt-5'>
        <form id='claim-filter' className='row align-items-center'>
          <div className='col-sm-4'>
            <label>Start Date</label>
            <input
              type='date'
              value={filters.startDate}
              className='form-control'
              onChange={handleFilter('startDate')}
            />
          </div>

          <div className='col-sm-4'>
            <label>End Date</label>
            <input
              type='date'
              value={filters.endDate}
              className='form-control'
              onChange={handleFilter('endDate')}
            />
          </div>
          <div className='col-sm-3'>
            <button className='btn btn-primary mt-4' onClick={searchClaims}>
              Search
            </button>
          </div>
          <div className='col-sm-1'>
            <button className='btn btn-danger mt-4' onClick={clearFilters}>
              <span className='bi bi-x-circle'></span>
            </button>
          </div>
        </form>
      </div>
      {/* <div>
          <Link to='/claims/upload' className='btn btn-primary'>
            Bulk Upload
          </Link>
        </div> */}
      <br />
      <div className='table-responsive'>
        <table className='table table-bordered table-hover'>
          <thead>
            <tr>
              <th scope='col-2'>Actions</th>
              <th scope='col'>Date</th>
              <th scope='col'>Beneficiary</th>
              <th scope='col'>Subscription Code</th>
              <th scope='col'>Pharmacy Name</th>
              <th scope='col'>Amount</th>
              <th scope='col'>Claim Status</th>

              <th scope='col'>NPS</th>
              <th scope='col'>Notes</th>
              <th scope='col'>Data Entry Mode</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item: IClaims) => {
              return (
                <tr key={item.insuranceClaimId}>
                  <td>
                    <i
                      onClick={() => editClaimsData(item)}
                      className='bi bi-pencil-fill update-icon mr-2'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Edit Claims Data'
                    ></i>
                    {/* <i
                      onClick={() => editClaimsPharmacyRatingsData(item)}
                      className='bi bi-pen-fill update-icon'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Edit Claim Pharmacy Ratings'
                    ></i> */}
                  </td>
                  <td>{formatDate(item.claimDate)}</td>
                  <td>{item.beneficiaryName}</td>
                  {item.subscriptionCode ? (
                    <td
                      style={{ cursor: 'pointer', color: '#1489C8' }}
                      onClick={() => loadSubscriptionDetails(item.subscriptionCode)}
                    >
                      {item.subscriptionCode}
                    </td>
                  ) : (
                    <td>{item.providerClaimCode}</td>
                  )}
                  <td
                    style={{ cursor: 'pointer', color: '#1489C8' }}
                    onClick={() => loadPharmacyDetails(item.pharmacyCode)}
                  >
                    {item.pharmacyName}
                  </td>
                  <td>{item.claimAmount}</td>
                  <td>{item.claimStatus}</td>
                  <td>{item.nps}</td>
                  <td>{item.notes}</td>
                  <td>{item.creationMode}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <br />
      {showPagination ? (
        <PaginationBar
          currentPageNumber={pageInfo.pageIndex}
          numberOfPages={pageInfo.pageCount}
          callback={paginationCallback}
        />
      ) : (
        ''
      )}
    </Fragment>
  );
};

export default ClaimsTable;
