import { getTodayDateInput } from '../utils/dateTimeUtils';
import { IBeneficiary } from './BeneficiaryInterface';
import { pharmacyRating } from './PharmacyInterface';
import { IPharmacyRating } from './PharmacyRatingInterface';

export interface EditClaim {
  open: boolean;
  close: () => void;
  data: IClaims;
  handleChange: (input: string) => (event: any) => void;
  loadClaims: Function;
}

export interface IAddClaimModal {
  open: boolean;
  close: () => void;
  modalData: IBeneficiary;
  pharmacyList: Array<any>;
}

export interface IClaimDataExport {
  startDate: string;
  endDate: string;
  email: string;
}

export interface InsuranceClaimDrug {
  localId: number; //local value to track object
  name: string;
  dose: string;
  cost: number;
  frequency: string;
  notes: string;
  isDispensed: boolean;
}

export interface InsuranceClaimTest {
  localId: number; //local value to track object
  testName: string;
  cost: number;
  testResult: string;
  fileName: string;
  notes: string;
  isCarriedOut: boolean;
}

export interface IClaims {
  insuranceClaimId: number;
  beneficiaryName: string;
  subscriptionCode: string;
  providerClaimCode: string;
  beneficiaryId: number;
  claimDate: string;
  claimAmount: number;
  pharmacyId: number;
  pharmacyName: string;
  pharmacyCode: string;
  notes: string;
  nps: number;
  claimProvider: string;
  claimStatus: string;
  creationMode: string;
  dateUpdated: string;
  dateCreated: string;
  //rating: IPharmacyRating;
}

export interface IPharmacyRatingClaims {
  rating: IPharmacyRating;
  pharmacyCode: string;
  insuranceClaimId: number;
}

export interface IPostClaimDTO {
  // insuranceClaimId: number;
  subscriptionCode: string;
  beneficiaryId: number;
  claimDate: string;
  pharmacyId: number;
  pharmacyName: string;
  pharmacyCode: string;
  notes: string;
  nps: number;
  setPrimaryPharmacy: boolean;
  claimProvider: string;
  claimStatus: string;
  rating: IPharmacyRating;
  drugs: Array<InsuranceClaimDrug>;
  tests: Array<InsuranceClaimTest>;
  payInstantly: boolean;
}

export interface IPostHospicashClaims {
  subscriptionCode: string;
  beneficiaryId: number;
  claimDate: string;
  claimAmount: number;
  pharmacyName: string;
  notes: string;
  nps: number;
  claimStatus: string;
  claimType: string;
  documentsUploaded: Array<string>;
}

export interface IClaimEligibility {
  eligible: boolean;
  reason: string;
}

export interface IClaimUploadStatus {
  isUploadSuccessful: boolean;
  pharmacyCode: string;
  phoneNumber: string;
  statusMessage: string;
}

export const initialClaim: IClaims = {
  beneficiaryName: '',
  pharmacyName: '',
  pharmacyCode: '',
  insuranceClaimId: 0,
  subscriptionCode: '',
  providerClaimCode: '',
  beneficiaryId: 0,
  claimDate: '',
  pharmacyId: 0,
  claimAmount: 0,
  notes: '',
  nps: 0,
  claimProvider: 'WellaHealth',
  creationMode: '',
  claimStatus: 'Pending',
  dateUpdated: '',
  dateCreated: '',
};

export const initialPostClaim: IPostClaimDTO = {
  //  insuranceClaimId: 0,
  subscriptionCode: '',
  beneficiaryId: 0,
  claimDate: getTodayDateInput(),
  pharmacyId: 0,
  pharmacyCode: '',
  pharmacyName: '',
  notes: '',
  nps: 0,
  setPrimaryPharmacy: false,
  claimProvider: 'Wellahealth',
  claimStatus: 'Pending',
  rating: pharmacyRating,
  drugs: [],
  tests: [],
  payInstantly: false,
};

export const initialHospicashClaim: IPostHospicashClaims = {
  //  insuranceClaimId: 0,
  subscriptionCode: '',
  beneficiaryId: 0,
  claimDate: getTodayDateInput(),
  pharmacyName: '',
  notes: '',
  nps: 0,
  claimAmount: 0,
  claimStatus: 'Pending',
  claimType: 'HospitalPayout',
  documentsUploaded: [],
};

export const initialClaimDrug: InsuranceClaimDrug = {
  localId: 0,
  name: '',
  dose: '',
  cost: 0,
  frequency: '',
  notes: '',
  isDispensed: true,
};

export const initialClaimTest: InsuranceClaimTest = {
  localId: 0,
  testName: '',
  cost: 0,
  testResult: 'NotDone',
  fileName: '',
  notes: '',
  isCarriedOut: true,
};
